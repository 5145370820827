import logo from '../../images/logo.png';
import { Container, Navbar, Nav, NavDropdown } from 'react-bootstrap';
import LinkContainer from 'react-router-bootstrap/lib/LinkContainer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFile, faUser } from '@fortawesome/pro-duotone-svg-icons';
import { faCalendar } from '@fortawesome/pro-duotone-svg-icons';
import { useAppDispatch, useAppSelector } from '../hooks';
import { isLoggedIn, loggedAsName, logOut, selectAccountCarerId } from '../../features/account/accountSlice';
import { resetCarers } from '../../features/carers/carerSlice';
import { resetClients } from '../../features/clients/clientSlice';
import { resetShiftAssignments } from '../../features/shift-assignment/ShiftAssignmentSlice';
import { resetRoster } from '../../features/roster/RosterSlice';
import { resetBranches } from '../../features/branches/branchSlice';

export function Menu() {
    const dispatch = useAppDispatch();
    const loggedIn = useAppSelector(isLoggedIn);
    const loggedOnAs = useAppSelector(loggedAsName);
    const carerId = useAppSelector(selectAccountCarerId);
    
    const logOutAction = () => {
        dispatch(logOut());
        dispatch(resetCarers());
        dispatch(resetClients());
        dispatch(resetShiftAssignments());
        dispatch(resetRoster());
        dispatch(resetBranches());
    }

    /*
    Code to determine if un-read messages & chat link removed but ready to be placed back in if needed.

        const clients = useAppSelector(selectClients);

        const [unreadMessages, setUnreadMessages] = useState(<></>);
        useEffect(() => {
            if (clients.length > 0) {
                let readLastMessagesBool = false;
                for (let index = 0; index < clients.length; index++) {
                    const element = clients[index];
                    if ((element.lastChatMessageForClient && element.lastViewedChatMessage && element.lastChatMessageForClient > element.lastViewedChatMessage) ||
                    (element.lastChatMessageForClient && element.lastViewedChatMessage === null)) {
                        readLastMessagesBool = true;
                    }
                } 
                if (readLastMessagesBool) {
                    setUnreadMessages(<span className="chat-menu-badge">{readLastMessagesBool} <FontAwesomeIcon icon={faCircle}/></span>);
                }
                else
                {
                    setUnreadMessages(<></>);
                }
            }
            else
            {
                setUnreadMessages(<></>);
            }

        }, [clients]);

        <Nav.Item as="li">
            <LinkContainer to="/chat" className="position-relative">
                <Nav.Link eventKey="chat"><FontAwesomeIcon icon={faComments} /> Chat{unreadMessages}</Nav.Link>
            </LinkContainer>
        </Nav.Item>
    */

    let navToggle;
    let mainNav;
    let userOptions;
    if (loggedIn) {
        navToggle = (<Navbar.Toggle aria-controls="basic-navbar-nav" />);
        mainNav = (
            <Navbar.Collapse id="main-navbar-nav">
                <Nav className="me-auto" as="ul">
                    <Nav.Item as="li">
                        <LinkContainer to="/rotas">
                            <Nav.Link eventKey="rota"><FontAwesomeIcon icon={faCalendar} /> Rota</Nav.Link>
                        </LinkContainer>
                    </Nav.Item>
                    <Nav.Item as="li">
                        <LinkContainer to="/documents">
                            <Nav.Link eventKey="documents"><FontAwesomeIcon icon={faFile} /> Service Provider Toolkit</Nav.Link>
                        </LinkContainer>
                    </Nav.Item>
                    {carerId ? (
                        <Nav.Item as="li">
                            <LinkContainer to="/timesheet">
                                <Nav.Link eventKey="timesheet"><FontAwesomeIcon icon={faCalendar} /> Timesheet</Nav.Link>
                            </LinkContainer>
                        </Nav.Item>
                    ): ""}
                </Nav>
            </Navbar.Collapse>
        );

        userOptions = (
            <Navbar.Collapse className="justify-content-end" id="user-details">
                <Navbar.Text>
                    Signed in as: <b>{loggedOnAs}</b>
                </Navbar.Text>
                <NavDropdown title={<FontAwesomeIcon icon={faUser} />} id="user-dropdown">
                    <NavDropdown.Item onClick={() => logOutAction()}>Log out</NavDropdown.Item>
                </NavDropdown>
            </Navbar.Collapse>
        )
    }
    return (
        <Navbar bg="light" expand="lg">
            <Container>
                <LinkContainer to="/">
                    <Navbar.Brand>
                        <img src={logo} className="app-logo d-inline-block align-top" height="60" alt="Friends Helping at Home logo" />
                    </Navbar.Brand>
                </LinkContainer>
                {navToggle}
                {mainNav}
                {userOptions}
            </Container>
        </Navbar>
    )
}