import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Moment } from "moment";
import { RootState } from "../store";
import { CarerHolidayModel, RecurranceRange } from "../types";

export interface SignalRState {
  status: "idle" | "loading" | "failed";
  connectionState: signalR.HubConnectionState | undefined;
  rosterDateUpdates: Moment[];
  rosterDateRangeUpdates: RecurranceRange[];
  newCarerHolidays: CarerHolidayModel[];
  carerHolidaysToRemove: string[];
}

const initialState: SignalRState = {
  status: "idle",
  connectionState: undefined,
  rosterDateUpdates: [],
  rosterDateRangeUpdates: [],
  newCarerHolidays: [],
  carerHolidaysToRemove: [],
};

export const signalRSlice = createSlice({
  name: "signalR",
  initialState,
  reducers: {
    newCarerHoliday: (state, action: PayloadAction<CarerHolidayModel>) => {
      const index = state.newCarerHolidays.findIndex(
        (x) => x.id === action.payload.id
      );
      if (index === -1) {
        state.newCarerHolidays.push(action.payload);
      }
    },
    newCarerHolidayHandled: (
      state,
      action: PayloadAction<CarerHolidayModel>
    ) => {
      const index = state.newCarerHolidays.findIndex(
        (x) => x.id === action.payload.id
      );
      if (index !== -1) {
        state.newCarerHolidays.splice(index, 1);
      }
    },
    newCarerHolidayRemoval: (state, action: PayloadAction<string>) => {
      const index = state.carerHolidaysToRemove.findIndex(
        (x) => action.payload
      );
      if (index === -1) {
        state.carerHolidaysToRemove.push(action.payload);
      }
    },
    carerHolidayRemovalHandled: (state, action: PayloadAction<string>) => {
      const index = state.carerHolidaysToRemove.findIndex(
        (x) => x === action.payload
      );
      if (index !== -1) {
        state.carerHolidaysToRemove.splice(index, 1);
      }
    },
    rosterDateUpdated: (state, action: PayloadAction<Moment>) => {
      const index = state.rosterDateUpdates.findIndex(
        (x) => x === action.payload
      );
      if (index === -1) {
        state.rosterDateUpdates.push(action.payload);
      }
    },
    rosterDateUpdateHandled: (state, action: PayloadAction<Moment>) => {
      const index = state.rosterDateUpdates.findIndex(
        (x) => x === action.payload
      );
      if (index !== -1) {
        state.rosterDateUpdates.splice(index, 1);
      }
    },
    rosterDateRangeUpdated: (state, action: PayloadAction<RecurranceRange>) => {
      const index = state.rosterDateRangeUpdates.findIndex(
        (x) => x.start === action.payload.start && x.end === action.payload.end
      );
      if (index === -1) {
        state.rosterDateRangeUpdates.push(action.payload);
      }
    },
    rosterDateRangeUpdateHandled: (
      state,
      action: PayloadAction<RecurranceRange>
    ) => {
      const index = state.rosterDateRangeUpdates.findIndex(
        (x) => x.start === action.payload.start && x.end === action.payload.end
      );
      if (index !== -1) {
        state.rosterDateRangeUpdates.splice(index, 1);
      }
    },
    clearShifts: (state) => {
      state.rosterDateUpdates = [];
      state.rosterDateRangeUpdates = [];
    },
    setConnectionState: (
      state,
      action: PayloadAction<signalR.HubConnectionState | undefined>
    ) => {
      state.connectionState = action.payload;
    },
  },
});

export const {
  newCarerHoliday,
  newCarerHolidayHandled,
  newCarerHolidayRemoval,
  carerHolidayRemovalHandled,
  rosterDateRangeUpdated,
  rosterDateUpdated,
  rosterDateUpdateHandled,
  rosterDateRangeUpdateHandled,
  clearShifts,
  setConnectionState,
} = signalRSlice.actions;

export const selectRosterDateUpdates = (state: RootState) =>
  state.signalR.rosterDateUpdates;

export const selectRosterDateRangeUpdates = (state: RootState) =>
  state.signalR.rosterDateRangeUpdates;

export const selectNewCarerHolidayUpdates = (state: RootState) =>
  state.signalR.newCarerHolidays;

export const selectCarerHolidayRemovalUpdates = (state: RootState) =>
  state.signalR.carerHolidaysToRemove;

export const getConnectionState = (state: RootState) =>
  state.signalR.connectionState;

export default signalRSlice.reducer;
